.programs-details-section {
  background-color: #ffffff;
  padding: 20px;
  font-family: "Inter", sans-serif;
}

.details-title {
  font-size: clamp(2rem, 1.5vw, 2.8rem);
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.programs-list {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.program-item {
  margin-bottom: 20px;
}

.program-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.program-image {
  width: 250px;
  min-width: 250px;
  height: auto;
}

.program-description-wrapper {
  flex: 1;
}

.rtl .program-content {
  flex-direction: row-reverse;
}

.program-title {
  font-size: clamp(1.5rem, 1.2vw, 2rem);
  font-weight: bold;
  color: #444;
  margin-bottom: 10px;
}

.program-description {
  font-size: clamp(1rem, 1.2vw, 1.25rem);
  color: #555;
  line-height: 1.6;
  text-align: justify;
  flex: 1;
}

.rtl .programs-details-section {
  direction: rtl;
  text-align: right;
}

.rtl .details-title,
.rtl .program-title,
.rtl .program-description {
  font-family: "Noto Kufi Arabic", sans-serif !important;
}

/* Media Queries */
@media (max-width: 768px) {
  .program-content {
    flex-direction: column;
    align-items: center;
  }

  .program-image {
    margin: 10px 0;
    width: 100%;
  }

  .program-title {
    font-size: 1.5rem;
    text-align: center; /* Center title on mobile */
  }

  .program-description {
    font-size: 1rem;
    text-align: center; /* Center description on mobile */
  }

  .rtl .program-content {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center; /* Ensure everything is centered in RTL */
  }

  .rtl .details-title {
    text-align: center; /* Center title in RTL mode */
  }

  .rtl .program-title {
    text-align: center; /* Center program title in RTL */
  }

  .rtl .program-description {
    text-align: center; /* Center program description in RTL */
  }
}
