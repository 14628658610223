/* Navbar.css */

nav {
  background-color: #fff;
  font-family: "Inter", sans-serif;
  padding: 30px 30px;
  position: fixed;
  top: 0; /* Stick to the top */
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it stays on top of other content */
  height: 50px; /* Adjust height as needed */
  transition: box-shadow 0.3s ease; /* Smooth transition for the shadow */
}

.nav-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow when scrolled */
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

.logo-container {
  margin-right: 20px;
}

.logo-image {
  height: 45px;
  transition: transform 0.3s ease; /* Transition for rotation */
}

.nav-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 45px; /* Adjust the gap as needed */
  justify-content: center; /* Center the items horizontally */
  flex-wrap: nowrap; /* Prevent wrapping onto a new line */
}

.nav-item {
  padding-bottom: 10px;
  width: 100%;
}

.link {
  text-decoration: none;
  color: #6d717c;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  transition: color 0.3s ease; /* Transition for color */
  white-space: nowrap; /* Prevent text from wrapping to a new line */
}

.active-link {
  font-weight: 700;
  color: #0579ce;
  font-family: "Inter", sans-serif;
  font-size: 18px;
}

.language-toggle {
  margin-left: 20px;
  cursor: pointer;
  font-weight: 600;
  color: #6d717c;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  transition: color 0.3s ease; /* Transition for color */
  white-space: nowrap;
}

.language-toggle span {
  cursor: pointer;
  margin: 0 5px; /* Add some space between the language options */
}

.active-language {
  font-weight: 700;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
}

body {
  margin-top: 70px; /* Offset the fixed navbar height */
}

.mobile-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.menu-icon {
  cursor: pointer;
  font-size: 30px; /* Increased the size of the icon */
  margin-left: 20px; /* Added space between the language selector and the icon */
}

.icon-image {
  width: 30px; /* Increased the size of the three dots icon */
  height: 30px; /* Adjusted the height to match the width */
  color: #000;
}

.overlay {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
  background-color: #004080;
  z-index: 1000;
  padding: 10px 20px;
  box-sizing: border-box;
  transform: translateY(-100%);
  opacity: 0;
}

.nav-links-open {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.link-mobile {
  text-decoration: none;
  color: #fff;
  font-weight: 300;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  padding: 10px 0;
  display: block;
  width: 100%;
  transition: color 0.3s ease; /* Transition for color */
}

.active-link-mobile {
  font-weight: 300;
  color: #ffd700;
  font-family: "Inter", sans-serif;
  font-size: 18px;
}

.link-mobile,
.active-link-mobile,
.link.arabic-text,
.active-link.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif !important;
  font-size: 17px;
  font-weight: 500;
}

.active-link-mobile,
.active-link.arabic-text {
  font-weight: 600;
}

.active-link.arabic-text {
  color: #0579ce;
}

/* Keyframes for slide down/up animations and logo rotation */
@keyframes slideDownMenu {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUpMenu {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.logo:hover {
  transform: rotate(-10deg) scale(1.3);
}

/* Desktop Hover Styles */
.desktop-nav-item:hover {
  color: #0579ce; /* Light Cyan color on hover */
}

.language-toggle-desktop:hover {
  color: #0579ce; /* Light Cyan color on hover */
}

/* Mobile Hover Styles */
.mobile-nav-item:hover {
  color: #ffd700; /* Yellow color on hover for mobile */
}

.language-toggle-mobile:hover {
  color: #ffd700; /* Yellow color on hover for mobile */
}

@media (max-width: 768px) {
  body {
    margin-top: 100px; /* Adjust this value based on the navbar's height on smaller screens */
  }
}
