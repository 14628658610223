/* WordTransition Component Styles */
.word-transition-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  font-family: "Inter", sans-serif;
  position: relative;
  overflow: hidden;
  max-width: 1000px;
  margin: auto;
}

.word-transition-banner-v2-content-wrap {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add subtle shadow for depth */
}

.word-transition-banner-v2-details-wrap {
  flex: 1;
  padding: 50px;
  background-color: transparent;
  position: relative;
  z-index: 3; /* Make sure the text is above the animation */
}

.word-transition-banner-title-v2-big {
  font-size: clamp(1.8rem, 3.5vw, 2.5rem); /* Slightly reduced sizes */
  font-weight: 800;
  margin: 0;
  color: #48b4fd;
  font-family: "Inter", sans-serif;
  max-width: 100%;
  line-height: 1.1;
  white-space: normal;
}

.word-transition-banner-title-v2-small {
  font-size: clamp(1.6rem, 3vw, 2rem); /* Slightly reduced sizes */
  margin-top: 10px;
  color: #0579ce;
  font-family: "Inter", sans-serif;
  max-width: 100%;
  line-height: 1.2;
  white-space: normal;
  display: inline-block;
  animation: wordTransitionFadeInUp 0.5s ease-in-out; /* Add animation */
}

@keyframes wordTransitionFadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.countdown-circle {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  z-index: 4;
  background: conic-gradient(#0579ce 0deg, #ffffff 360deg);
  cursor: pointer;
}

.arabic-text .countdown-circle {
  right: auto;
  left: 20px;
}

/* Arabic Text Styles */
.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif !important; /* Arabic font */
  text-align: right;
}

.rtl {
  direction: rtl;
}

.word-transition-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.arrow {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 0 15px;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.arrow:hover {
  opacity: 1;
}

.indicators {
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.indicator.active {
  background-color: #0579ce;
}

.indicator:hover {
  background-color: #48b4fd;
}

.arabic-text .word-transition-controls {
  flex-direction: row-reverse;
}
