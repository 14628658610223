/* Metrics Section Styles */
.metrics-section {
  display: flex;
  justify-content: space-between; /* Evenly space the items */
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
  padding: 50px 0;
  font-family: "Inter", sans-serif;
  max-width: 1200px; /* Limit background color width */
  margin: 0 auto; /* Center the section */
  border-radius: 10px; /* Optional: Add rounded corners */
  background-color: rgba(233, 247, 255, 0.3); /* Same color with 50% opacity */
}

.metric-item {
  text-align: center;
  margin: 0 20px;
  font-size: clamp(2rem, 1.5vw, 2.8rem);
  color: #333;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center; /* Ensures text stays centered */
  cursor: pointer; /* Add cursor to indicate interactiveness */
  flex: 1; /* Ensures items take up equal space */
}

.metric-plus {
  font-size: clamp(2rem, 2.5vw, 3rem); /* Enlarged the plus sign */
  color: #555;
  margin-left: 5px;
}

.metric-label {
  font-size: clamp(1.2rem, 1.2vw, 1.5rem);
  color: #666;
  margin-top: 10px;
  line-height: 1.5;
  font-family: "Inter", sans-serif;
  white-space: normal;
}

.metric-separator {
  font-size: clamp(2rem, 1.5vw, 2.8rem);
  color: #999;
  margin: 0 10px;
  align-self: center;
}

.rtl .metric-item {
  text-align: center;
  direction: rtl;
}

.metrics-section {
  overflow-wrap: break-word;
  word-wrap: break-word;
  line-height: 1.2;
  hyphens: none;
}

/* Hover Effect */
.metric-item:hover {
  transform: scale(1.05);
}

/* Media Queries */
@media (max-width: 768px) {
  .metrics-section {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }

  .metric-item {
    font-size: 2rem;
    margin-bottom: 20px; /* Add spacing between items */
  }

  .metric-plus {
    font-size: 2.5rem; /* Adjusted for responsive design */
  }

  .metric-label {
    font-size: 1rem;
  }

  .metric-separator {
    display: none; /* Hide separators on small screens */
  }
}
