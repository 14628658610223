.footer {
  background-color: #fff;
  color: #000;
  padding: 5px 0;
  text-align: center;
  font-family: "Noto Kufi Arabic", sans-serif !important;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Shadow on top */
  width: 100%;
  position: static; /* Remove fixed positioning */
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-links {
  margin-bottom: 0px; /* Slightly reduce margin below icons */
  display: flex;
  justify-content: center;
}

.social-button {
  display: inline-block;
  padding: 8px; /* Keep padding to make buttons large */
  margin-right: 10px; /* Corrected: Keep some space between buttons */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Keep buttons large */
  height: 50px; /* Keep buttons large */
  transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
}

.social-button:hover {
  transform: scale(1.2); /* Zoom in effect */
}

.social-icon {
  width: 24px; /* Keep icon size large */
  height: 24px; /* Keep icon size large */
}

.contact-info {
  margin-top: -10px; /* Ensure there's a slight gap above contact info */
  margin-bottom: -10px; /* Reduce space between contact info and copyright */
}

.contact-info a {
  color: #48b4fd;
}

.copyright-info {
  margin-top: 3px; /* Reduce space between copyright and contact info */
  font-size: 14px; /* Slightly reduce font size */
}
