/* ContentSection.css */
.content-section {
  background-color: #ffffff; /* White background */
  padding: 20px 20px; /* Adjusted padding */
  font-family: "Inter", sans-serif;
}

.content-title {
  font-size: clamp(
    2rem,
    1.5vw,
    2.8rem
  ); /* Consistent font size with Hero Section */
  font-weight: bold;
  margin-bottom: 10px; /* Reduced margin */
  color: #333; /* Match the title color in Hero Section */
  text-align: center; /* Center the title */
}

.content-paragraph {
  font-size: clamp(1rem, 1.2vw, 1.25rem); /* Adjusted font size */
  color: #555;
  line-height: 1.6;
  margin-bottom: 10px; /* Reduced margin */
  text-align: justify;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.rtl .content-section {
  direction: rtl;
  text-align: right;
}

.rtl .content-title,
.rtl .content-paragraph {
  font-family: "Noto Kufi Arabic", sans-serif !important;
}

/* Media Queries */
@media (max-width: 768px) {
  .content-title {
    font-size: 2rem;
  }

  .content-paragraph {
    font-size: 1rem;
  }

  .horizontal-line {
    max-width: 100%; /* Allows full width on smaller screens */
  }
}
