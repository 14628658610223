/* Base styles for all screens */
.timeline-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 1000px;
  font-family: "Noto Kufi Arabic", sans-serif !important; /* Arabic font */
  font-size: 16px; /* Increased base font size */
  line-height: 1.5; /* Improved readability */
  font-weight: 400;
}

.timeline-container.en {
  font-family: "Inter", sans-serif !important; /* English font */
  font-weight: 400; /* Ensure consistent font weight */
  font-size: 16px; /* Increased base font size for English */
  line-height: 1.5; /* Improved readability */
}

.timeline-image {
  width: 100%;
  height: 150px; /* Fixed height for all images */
  object-fit: contain; /* Fit the image within the bounds without cropping */
  border-radius: 8px;
  margin-top: 0; /* Removed top margin */
  margin-bottom: 0px;
}

.timeline-content {
  overflow: hidden;
  word-wrap: break-word;
  max-width: 100%;
  font-weight: 400; /* Ensure consistent font weight */
  font-size: 1rem; /* Slightly increased font size */
  line-height: 1.6; /* Adjusted for readability */
  margin-top: 0; /* Removed top margin */
}

/* Alignment adjustments for desktop version */
.timeline-content.arabic-text,
.timeline-opposite-content.arabic-text {
  text-align: right; /* Right-align Arabic text for desktop */
  direction: rtl; /* Ensure text flows from right to left */
}

.timeline-content.en,
.timeline-opposite-content.en {
  text-align: left; /* Left-align English text for desktop */
  direction: ltr; /* Ensure text flows from left to right */
}

/* Alignment adjustments for mobile version */
@media only screen and (max-width: 768px) {
  .timeline-content,
  .timeline-opposite-content {
    text-align: center; /* Center-align all text for mobile */
    margin-bottom: 20px; /* Increase space under the description */
    margin-top: 0; /* Removed top margin */
  }
}

.timeline-opposite-content {
  overflow: hidden;
  word-wrap: break-word;
  max-width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the content vertically */
  font-weight: 400; /* Ensure consistent font weight */
  font-size: 1rem; /* Slightly increased font size */
  line-height: 1.6; /* Adjusted for readability */
  margin-top: 0; /* Removed top margin */
}

.timeline-item {
  margin-bottom: 50px; /* Space between timeline items */
  position: relative;
  min-height: 120px; /* Ensure enough height for the connector line */
  font-weight: 400; /* Ensure consistent font weight */
  margin-top: 0; /* Removed top margin */
}

.timeline-separator {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Ensure the line aligns with the dot */
  height: 100%; /* Ensure the separator takes up the full height */
  margin-top: 0; /* Removed top margin */
}

.timeline-dot {
  margin-top: 0;
  margin-bottom: 20px;
}

.timeline-connector {
  background-color: #1976d2; /* Ensure consistent line color */
  min-height: 70px; /* Increase this value to make the line longer */
  margin-top: 0; /* Removed top margin */
}

.timeline-year {
  font-weight: 600 !important; /* Try a bolder weight if 600 isn't working */
  display: inline-block;
  line-height: 1; /* Ensure proper alignment with the timeline dot */
  vertical-align: middle; /* Align vertically in the middle */
  display: inline-block;
  margin-top: 0; /* Removed top margin */
}

.timeline-title {
  display: inline-block;
  vertical-align: middle; /* Align vertically in the middle */
  font-weight: 400;
  margin-right: 8px; /* Adjust spacing as needed */
  margin-left: 0; /* Reset margin */
  line-height: 1; /* Ensure proper alignment with the timeline dot */
  margin-top: 0; /* Removed top margin */
}

/* Center alignment for title and description */
.desktop-banner-title-v2-big-title,
.mobile-banner-title-v2-big-title {
  font-size: clamp(
    2rem,
    1.5vw,
    2.8rem
  ); /* Consistent font size with Hero Section */
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif; /* Default English font */
  color: #333; /* Match the title color in Hero Section */
  text-align: center; /* Center the title */
  margin-top: 0; /* Removed top margin */
}

/* Ensure proper centering on mobile */
.mobile-banner-title-v2-big-title {
  font-size: clamp(
    2rem,
    1.5vw,
    2.8rem
  ); /* Consistent font size with Hero Section */
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif; /* Default English font */
  color: #333; /* Match the title color in Hero Section */
  text-align: center; /* Center the title */
  margin-top: 0; /* Removed top margin */
}

.mobile-banner-details-v2-title {
  text-align: center;
  margin: 0 auto 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Take full width */
  max-width: 90%; /* Adjust max-width to avoid any overflow issues */
  margin-top: 0; /* Removed top margin */
}

/* Container adjustments for mobile */
.timeline-container.mobile-timeline {
  text-align: center; /* Center align content */
  margin: 0 auto;
  padding: 20px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center all child elements */
  margin-top: 0; /* Removed top margin */
}

.mobile-banner-details-v2-title {
  text-align: center; /* Center align the description text */
  margin: 0 auto 20px auto; /* Center the description container */
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem; /* Adjust the font size if needed */
  margin-top: 0; /* Removed top margin */
}

.desktop-banner-details-v2-title {
  margin: 0 auto 20px auto; /* Center the description container */
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem; /* Adjust the font size if needed */
  margin-top: 0; /* Removed top margin */
}

/* Mobile Styles */
.mobile-timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  text-align: center; /* Center align text for both English and Arabic */
  margin-top: 0; /* Removed top margin */
}

.mobile-timeline-item .timeline-image {
  max-width: 80%; /* Limit the width to 80% of the container */
  max-height: 150px; /* Limit the height to 150px */
  width: auto; /* Ensure the image scales proportionally */
  height: auto; /* Ensure the image scales proportionally */
  object-fit: contain; /* Ensure the image is fully visible */
  margin-bottom: 15px;
  margin-top: 0; /* Removed top margin */
}

.mobile-timeline-item .timeline-year {
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-align: center; /* Ensure year is centered */
  margin-top: 0; /* Removed top margin */
}

.mobile-timeline-item .timeline-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center; /* Ensure title is centered */
  margin-top: 0; /* Removed top margin */
}

.mobile-timeline-item .timeline-text {
  font-size: 1rem;
  line-height: 1.5;
  text-align: center; /* Ensure text is centered */
  margin-top: 0; /* Removed top margin */
}

/* Base styles for title centering */
.desktop-banner-title-v2-big-title,
.mobile-banner-title-v2-big-title {
  font-size: clamp(2rem, 1.5vw, 2.8rem);
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
  color: #333;
  text-align: center;
  margin-top: 0;
}

/* Ensure proper centering for Arabic text */
.desktop-banner-title-v2-big-title.arabic-text {
  direction: rtl; /* Set text direction to right-to-left */
  text-align: center; /* Center the text */
  margin-right: auto; /* Ensure no unintended margins are applied */
  margin-left: auto; /* Ensure no unintended margins are applied */
}

/* Similar adjustments for mobile */
.mobile-banner-title-v2-big-title.arabic-text {
  direction: rtl;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
