/* Desktop Styles */
.desktop-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0; /* Removed side padding to allow full-width alignment */
  font-family: "Inter", sans-serif; /* Default English font */
}

.desktop-banner-v2-content-wrap {
  display: flex;
  max-width: 1200px;
  max-height: 600px;
  background-color: rgba(233, 247, 255, 0.3); /* Same color with 50% opacity */
  border-radius: 10px;
  position: relative;
  padding: 0; /* Removed padding to ensure full width alignment */
}

.desktop-banner-v2-details-wrap {
  flex: 1;
  padding: 50px;

  position: relative;
}

.desktop-banner-title-v2-big {
  font-size: clamp(2rem, 1.5vw, 2.8rem); /* Further reduced max size */
  font-weight: 700;
  margin: 0;
  color: #333;
  font-family: "Inter", sans-serif; /* English font */
  max-width: 100%; /* Stricter width to force text to wrap within two lines */
  line-height: 1.1; /* Adjust line-height for better spacing */
  white-space: normal; /* Ensure text wraps */
}

.desktop-banner-title-v2-small {
  font-size: clamp(1.5rem, 1.2vw, 2rem); /* Adjusted clamp values */
  margin-top: 10px;
  color: #555;
  font-family: "Inter", sans-serif; /* English font */
  max-width: 100%; /* Stricter width to keep text wrapping consistent */
  line-height: 1.2; /* Adjust line-height for better spacing */
  white-space: normal; /* Ensure text wraps */
}

.desktop-banner-details-v2 {
  margin: 20px 0;
  font-size: clamp(1.2rem, 1.2vw, 1.5rem); /* Adjusted clamp values */
  color: #666;
  font-family: "Inter", sans-serif; /* English font */
  max-width: 100%; /* Keep consistent width for details */
  line-height: 1.5; /* Adjust line-height for better spacing */
  white-space: normal; /* Ensure text wraps */
}

.desktop-banner-btns-wrapper {
  margin-top: 30px;
}

.desktop-primary-button {
  background-color: #007bff;
  color: white;
  padding: 12px 30px;
  text-decoration: none;
  border-radius: 30px;
  display: inline-block;
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-family: "Inter", sans-serif; /* English font */
}

/* Image Wrappers */
.desktop-banner-v2-img-wrapper {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: 0;
}

.desktop-banner-v2-img-left {
  justify-content: flex-start;
  margin-left: 0;
  margin-right: 0;
}

.desktop-banner-v2-img-right {
  justify-content: flex-end;
  margin-right: 0;
  margin-left: 20px; /* Edge position */
}

.desktop-banner-v2-main-img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  position: relative;
  right: 0;
  top: 0;
  border-radius: 10px;
  animation: FadeInUp 0.5s ease-in-out;
}

/* Image Visibility */
.desktop-banner-v2-img-left {
  display: none;
}

.desktop-banner-v2-img-right {
  display: flex;
}

.rtl .desktop-banner-v2-content-wrap {
  flex-direction: row-reverse; /* Ensure content is reversed in RTL mode */
  margin-left: 0; /* No margin on the left */
  margin-right: 0; /* No margin on the right */
  padding-left: 0; /* No padding on the left */
  padding-right: 0; /* No padding on the right */
}

.rtl .desktop-banner-v2-img-left {
  display: flex; /* Ensure the left image is visible in RTL */
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  justify-content: flex-end; /* Align to the left */
}

.rtl .desktop-banner-v2-img-right {
  display: none; /* Hide the right image in RTL mode */
}

/* Doodle Positioning */
.desktop-banner-shape {
  position: absolute;
  bottom: 0px;
  left: 5px;
  width: 100px;
  height: auto;
}

.desktop-banner-shape.arabic-text {
  left: auto;
  right: 5px; /* Move to the opposite corner */
}

/* Mobile Styles */
.mobile-hero-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 0;
  height: 65vh; /* Set height to 60% of the viewport height */
  justify-content: flex-start;
  padding-top: 20px;
  font-family: "Inter", sans-serif;
}

.mobile-banner-img-wrapper {
  position: relative;
  width: 100%;
  height: 100%; /* Fill the entire height of the mobile hero section */
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-banner-img {
  width: 100%;
  height: 100%; /* Fill the parent container */
  object-fit: cover; /* Ensure the image covers the area without stretching */
  border-radius: 10px;
}

.mobile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 60, 139, 0.6); /* Dark blue with 60% opacity */
  border-radius: 10px;
  z-index: 1;
}

.mobile-text-overlay {
  position: absolute;
  z-index: 2;
  color: white;
  text-align: left;
  top: 20%; /* Adjust position from the top */
  left: 10%; /* Align to the left with some padding */
  padding: 0 10px;
  margin: 0;
  width: 80%;
  transform: translateY(-20%);
}

.mobile-banner-title-v2-big {
  font-size: clamp(2rem, 4vw, 2.5rem);
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  max-width: 90%;
  line-height: 1.1;
  white-space: normal;
}

.mobile-banner-title-v2-small {
  font-size: clamp(1.5rem, 3vw, 2rem);
  margin-bottom: 10px;
  font-weight: 300;
  font-family: "Inter", sans-serif;
  max-width: 90%;
  line-height: 1.2;
  white-space: normal;
}

.mobile-banner-details-v2 {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  margin: 10px 0;
  font-weight: 200;
  font-family: "Inter", sans-serif;
  max-width: 90%;
  line-height: 1.5;
  white-space: normal;
}

.mobile-primary-button {
  background-color: #00aeff;
  color: white;
  padding: 12px 30px;
  text-decoration: none;
  border-radius: 30px;
  display: inline-block;
  font-size: clamp(1rem, 3vw, 1.25rem);
  margin-top: 20px;
  z-index: 2;
  font-family: "Inter", sans-serif;
}

/* Arabic Text Styles */
.desktop-banner-title-v2-big.arabic-text,
.desktop-banner-title-v2-small.arabic-text,
.desktop-banner-details-v2.arabic-text,
.desktop-primary-button.arabic-text,
.mobile-banner-title-v2-big.arabic-text,
.mobile-banner-title-v2-small.arabic-text,
.mobile-banner-details-v2.arabic-text,
.mobile-primary-button.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif !important; /* Arabic font */
}

.desktop-banner-title-v2-big,
.desktop-banner-title-v2-small,
.desktop-banner-details-v2,
.mobile-banner-title-v2-big,
.mobile-banner-title-v2-small,
.mobile-banner-details-v2 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  line-height: 1.2;
  hyphens: none;
}

/* Media Queries */
@media (max-width: 1225px) {
  .desktop-banner-title-v2-big {
    font-size: 2rem;
  }

  .desktop-banner-title-v2-small {
    font-size: 1.5rem;
  }

  .desktop-banner-details-v2 {
    font-size: 1rem;
  }

  .desktop-primary-button {
    font-size: 1rem;
  }
}

@media (max-width: 786px) {
  .mobile-banner-title-v2-big {
    font-size: 2rem;
  }

  .mobile-banner-title-v2-small {
    font-size: 1.5rem;
  }

  .mobile-banner-details-v2 {
    font-size: 1rem;
  }

  .mobile-primary-button {
    font-size: 1rem;
  }
}

/* Button Zoom and Scroll Down on Hover */
.desktop-primary-button,
.mobile-primary-button {
  transition: transform 0.3s ease-in-out;
}

.desktop-primary-button:hover,
.mobile-primary-button:hover {
  transform: scale(1.1) translateY(2px);
}

/* Image Zoom In While Maintaining Size and Scroll Down */
.desktop-banner-v2-img-wrapper,
.mobile-banner-img-wrapper {
  overflow: hidden;
}

@keyframes FadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Ensure the container has rounded corners and hides overflow */
.desktop-banner-v2-img-wrapper,
.mobile-banner-img-wrapper {
  overflow: hidden;
  border-radius: 10px; /* Adjust this value to your desired corner radius */
}

.desktop-banner-v2-main-img {
  transition: transform 0.8s ease-in-out; /* Slow down the zoom effect */
  border-radius: inherit; /* Ensure the image respects the container's rounded corners */
}

.desktop-banner-v2-img-wrapper:hover .desktop-banner-v2-main-img {
  transform: scale(1.1); /* Zoom effect on hover */
}

/* Ensure the Arabic button aligns to the right */
.desktop-banner-btns-wrapper {
  display: flex;
  justify-content: flex-start; /* Align left by default */
}

.rtl .desktop-banner-btns-wrapper {
  justify-content: flex-end; /* Align to the right in RTL */
}

.desktop-primary-button.arabic-text {
  margin-left: auto; /* Push the button to the right */
}

.mobile-primary-button.arabic-text {
  text-align: right; /* Align the button to the right on mobile */
  display: inline-block;
  width: auto;
  margin-left: auto; /* Ensure the button sticks to the right */
  margin-right: 0;
}

/* Ensure the button is aligned correctly within its container */
.desktop-banner-btns-wrapper,
.mobile-banner-btns-wrapper {
  display: flex;
  justify-content: flex-start;
}

.rtl .desktop-banner-btns-wrapper,
.rtl .mobile-banner-btns-wrapper {
  justify-content: flex-end;
}
