/* Services Section */
.services-section {
  padding: 40px;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
}

.services-title {
  font-size: clamp(2rem, 2.5vw, 2.8rem);
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
  color: #1e88e5; /* Light blue color */
  text-align: center; /* Ensure the title is always centered */
  direction: ltr; /* Default to LTR */
}

/* RTL Support */
.rtl .services-title {
  direction: rtl; /* Switch direction for Arabic */
  text-align: center; /* Keep the title centered in RTL */
}

/* Cards container for desktop layout */
.services-cards {
  display: grid;
  grid-template-columns: repeat(
    3,
    minmax(0, 1fr)
  ); /* Ensure cards take equal space */
  gap: 20px; /* Ensure there is a gap between the cards */
  padding: 0 20px; /* Add padding to the container if needed */
  justify-items: center; /* Center the cards within the grid */
}

/* Individual card styling */
.service-card {
  background: #f0f4f8; /* Light background color */
  padding: 20px;
  border-radius: 8px;
  width: calc(100% - 40px); /* Adjust width to account for margin */
  margin: 20px; /* Ensure there is space between each card */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center; /* Center the text */
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Icon styling */
.service-icon {
  width: 65px;
  height: 65px;
  margin-bottom: 15px;
  min-width: 0px; /* Ensure a minimum width for the images */
}

/* Font styling for the text inside the cards */
.service-title {
  font-size: clamp(1.2rem, 2vw, 2rem);
  font-weight: bold;
  color: #1e88e5; /* Light blue color for title */
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: center; /* Ensure title text is centered */
  font-family: "Inter", sans-serif; /* Use the same font as the description text */
}

.service-description {
  font-size: clamp(1rem, 2vw, 1.5rem);
  color: #666;
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  text-align: center; /* Ensure description text is centered */
}

/* Responsive Layout */
@media (max-width: 1024px) {
  .services-cards {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Two cards per row on medium screens */
  }

  .service-card {
    min-height: 275px; /* Slightly reduce height on medium screens */
    margin: 0 10px; /* Adjust side margins for medium screens */
  }
}

@media (max-width: 768px) {
  .services-cards {
    grid-template-columns: 1fr; /* Stack cards vertically on small screens */
  }

  .service-card {
    width: 90%; /* Slightly narrower for better fit on small screens */
    max-width: 400px; /* Ensure max width on mobile */
    margin: 20px auto; /* Add margin to the top/bottom and center horizontally */
    min-height: 250px; /* Adjust the minimum height for small screens */
  }

  .services-title {
    font-size: clamp(1.8rem, 4vw, 2.5rem); /* Adjust title size for mobile */
  }

  .service-title {
    font-size: clamp(
      1.5rem,
      4vw,
      2rem
    ); /* Adjust title size for small screens */
  }

  .service-description {
    font-size: clamp(
      1.2rem,
      3vw,
      1.5rem
    ); /* Adjust text size for small screens */
  }
}

/* RTL Support */
.rtl .service-card {
  text-align: center; /* Ensure text is centered for RTL as well */
}

/* Arabic text styling */
.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif;
  direction: rtl;
  text-align: center; /* Center-align Arabic text */
}

/* Ensure proper alignment for RTL */
.rtl .service-card {
  direction: rtl; /* Ensure the card direction is right-to-left */
  text-align: center; /* Center-align the content inside the card */
  margin-left: auto; /* Ensure it adjusts for RTL context */
  margin-right: auto; /* Center it in the container */
}

.rtl .services-title {
  direction: rtl;
  text-align: center; /* Center title text */
}

.rtl .service-title,
.rtl .service-description {
  text-align: center; /* Ensure the text inside the card is centered */
}
