.consulting-section {
  padding: 20px;
  background-color: #ffffff; /* White background */
  font-family: "Inter", sans-serif;
  max-width: 1000px;
  margin: 0 auto;
}

.consulting-title-main {
  font-size: 2.7rem; /* Slightly increased font size */
  font-weight: 700;
  color: #1a1a1a; /* Darker shade for the main title */
  margin-bottom: 20px;
  text-align: center; /* Center the title */
  direction: ltr; /* Reset direction for centering */
}

.consulting-content {
  max-width: 100%;
  margin: 0 auto;
}

.consulting-item {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9; /* Very light gray background for subtle contrast */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.consulting-title {
  font-size: 2rem; /* Slightly increased font size */
  font-weight: 700;
  color: #333333; /* A readable dark shade for the title */
  text-align: left; /* Left-align the section title */
}

.consulting-description,
.consulting-additional-info {
  font-size: 1.2rem; /* Slightly increased font size */
  color: #4a4a4a; /* Darker gray for better readability */
  margin-top: 10px;
  line-height: 1.6;
  text-align: left; /* Left-align the description */
}

.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif !important;
  text-align: right; /* Align Arabic text to the right */
}

.rtl .consulting-title-main {
  direction: rtl; /* Set direction for Arabic */
  text-align: center; /* Center the title for Arabic as well */
}

.rtl .consulting-item {
  text-align: right; /* Right-align content in Arabic */
}

.rtl .consulting-description,
.rtl .consulting-additional-info {
  text-align: right; /* Right-align the description in Arabic */
}
