/* Why Us Section */

/* Container for the Why Us section */
.why-us-section {
  padding: 40px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* Title of the Why Us section */
.why-us-title {
  font-size: clamp(2rem, 1.5vw, 2.8rem); /* Consistent font size with Hero Section */
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif; /* Default English font */
  color: #333; /* Match the title color in Hero Section */
  text-align: center; /* Center the title */
}

/* Cards container for desktop layout */
.why-us-cards {
  display: flex;
  justify-content: center; /* Center the cards container */
  flex-wrap: wrap;
  gap: 20px; /* Add spacing between cards */
}

/* Individual card styling */
.why-us-card {
  background: #f9f9f9;
  padding: 20px;
  margin: 10px auto; /* Center the card by setting margins to auto */
  border-radius: 8px;
  width: 220px; /* Fixed width for consistency */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: left; /* Align text to left for consistency with Hero Section */
  min-height: 300px; /* Set minimum height to make cards taller */
  display: flex;
  flex-direction: column; /* Ensure content is vertically aligned */
  position: relative; /* Needed for icon positioning */
}

/* Hover effect for the cards */
.why-us-card:hover {
  transform: scale(1.05);
}

.why-us-card .why-us-icon {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  padding: 10px;
  background-color: rgba(255, 235, 59, 0.3); /* Default background color with low opacity */
  border-radius: 8px; /* Square corners */
  display: flex;
  align-items: center;
  justify-content: center;
}

.why-us-card.icon1 .why-us-icon {
  background-color: rgba(255, 235, 59, 0.3); /* Yellow with low opacity */
}

.why-us-card.icon2 .why-us-icon {
  background-color: rgba(244, 67, 54, 0.3); /* Red with low opacity */
}

.why-us-card.icon3 .why-us-icon {
  background-color: rgba(76, 175, 80, 0.3); /* Green with low opacity */
}

.why-us-card.icon4 .why-us-icon {
  background-color: rgba(33, 150, 243, 0.3); /* Blue with low opacity */
}

.why-us-icon.arabic-text {
  right: 15px; /* Position at top right for Arabic */
}

.why-us-icon:not(.arabic-text) {
  left: 15px; /* Position at top left for English */
}

/* Font styling for the text inside the cards */
.why-us-card-title {
  font-size: clamp(1.2rem, 1.5vw, 2rem); /* Consistent with the section */
  font-weight: bold;
  margin-top: 70px; /* Add space for the icon */
  font-family: "Inter", sans-serif; /* Default English font */
}

.why-us-card-description {
  font-size: clamp(1.2rem, 1.2vw, 1.5rem); /* Match with Hero Section details */
  color: #666; /* Match color with Hero Section details */
  font-family: "Inter", sans-serif; /* Default English font */
  line-height: 1.5; /* Consistent line-height with Hero Section */
  white-space: normal; /* Ensure text wraps */
}

/* Arabic text styling */
.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif;
  direction: rtl;
  text-align: middle;
}

/* Responsive Mobile Layout */
@media (max-width: 786px) {
  .why-us-cards {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center-align cards */
  }

  .why-us-card {
    width: 90%; /* Full width with padding on mobile */
    max-width: 400px; /* Ensure max width on mobile */
    min-height: 250px; /* Adjust the minimum height for mobile */
    margin-left: auto; /* Center card */
    margin-right: auto; /* Center card */
    padding-left: 10px; /* Reduce left padding */
    padding-right: 10px; /* Ensure balance with right padding */
  }

  .why-us-title {
    font-size: clamp(1.8rem, 3vw, 2.5rem); /* Adjust title size for mobile */
  }

  .why-us-text {
    font-size: clamp(1rem, 2.5vw, 1.5rem); /* Adjust text size for mobile */
  }
}

/* RTL Support */
.rtl .why-us-card {
  text-align: right; /* Align text to right for Arabic */
}

/* Arabic text styling */
.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif;
  direction: rtl;
  text-align: right; /* Ensure proper text alignment */
}

.why-us-title.arabic-centered {
  text-align: center; /* Center the text horizontally */
  direction: rtl; /* Maintain RTL direction */
  font-family: "Noto Kufi Arabic", sans-serif; /* Apply Arabic font */
}
